import {
  EditButton,
  SimpleForm,
  TopToolbar,
  Button,
  PasswordInput,
  EditBase,
  Toolbar,
  SaveButton,
} from "react-admin";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

const UserShowActions = (props: any) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <TopToolbar>
      <EditButton />
      <Button
        sx={{ color: "red" }}
        label="Cambiar contraseña"
        onClick={() => setOpen(true)}
      >
        <LockOpenIcon />
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>Cambiar contraseña</DialogTitle>
        <DialogContent>
          <EditBase
            mutationMode="pessimistic"
            redirect={false}
            transform={(data) => {
              setOpen(false);
              return { password: data.password };
            }}
          >
            <SimpleForm
              resource="Password"
              toolbar={<UserEditPasswordToolbar />}
              validate={(values: any) => {
                if (values.password !== values.confirmPassword) {
                  return {
                    confirmPassword: "Las contraseñas no coinciden",
                  };
                }
                if (values.password.length < 8) {
                  return {
                    password:
                      "La contraseña tiene que tener mínimo 8 caracteres",
                  };
                }
                return {};
              }}
            >
              <PasswordInput source="password" label="Contraseña" required />
              <PasswordInput
                source="confirmPassword"
                label="Confirmar contraseña"
                required
              />
            </SimpleForm>
          </EditBase>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

const UserEditPasswordToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
export default UserShowActions;
