import { ReferenceManyField, Pagination } from "react-admin";
import { ImageGallery } from "./ImageGallery";
import MediaUploadDialog from "./MediaUploadDialog";

export const UserMediaUpload = ({ user }: { user: { id: string } }) => {
  return (
    <>
      <MediaUploadDialog user={user} />
      <ReferenceManyField
        pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 20, 30, 40]} />}
        reference="Multimedia"
        target="userId"
        perPage={20}
      >
        <ImageGallery />
      </ReferenceManyField>
    </>
  );
};
