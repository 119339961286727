import {
  Labeled,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useRecordContext,
} from "react-admin";

export const FranquiciaInput = (props: any) => {
  const record = useRecordContext();
  if (record && record.role !== "FRANQUICIAADMIN") return <></>;
  return (
    <ReferenceInput source="franquiciaId" reference="Franquicia" required>
      <SelectInput {...props} optionText="nombre" required />
    </ReferenceInput>
  );
};

export const FranquiciaField = () => {
  const record = useRecordContext();
  if (record && record.role !== "FRANQUICIAADMIN") return <></>;
  return (
    <Labeled>
      <ReferenceField source="franquiciaId" reference="Franquicia" link="show">
        <TextField source="nombre" />
      </ReferenceField>
    </Labeled>
  );
};
