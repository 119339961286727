import {
  Datagrid,
  DeleteButton,
  EditButton,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  usePermissions,
} from "react-admin";
import PreviewImageField from "../../forms/PreviewImageField";
import { FranquiciaSingleShow } from "./components/FranquiciaSingleShow";
import { CreateWithDefaultValuesButton } from "../../forms/CreateWithDefaultValuesButton";
import { LimitedLenghtTextField } from "../../forms/LimitedLenghtTextField";

export const FranquiciaShow = () => {
  const { permissions } = usePermissions();

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Franquicia">
          <FranquiciaSingleShow />
        </Tab>
        {permissions === "SUPERADMIN" && [
          <Tab label="Clinicas">
            <ReferenceManyField
              reference="Clinica"
              target="franquiciaId"
              label={false}
              perPage={10}
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="nombre" />
                <TextField source="direccion" />
                <TextField source="telefono" />
                <ShowButton />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </Tab>,
          <Tab label="Calculadora">
            <CreateWithDefaultValuesButton
              resource="CalculadorEmbarazo"
              defaultValues={(context) => ({ franquiciaId: context.id })}
            />
            <ReferenceManyField
              reference="CalculadorEmbarazo"
              label=""
              perPage={10}
              target="franquiciaId"
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={false}>
                <PreviewImageField
                  width={100}
                  height={100}
                  objectFit="contain"
                  source="imageUrl"
                  label="Foto"
                />
                <NumberField source="semana" />
                <LimitedLenghtTextField source="mensaje" />
                <EditButton />
                <DeleteButton redirect={false} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>,
        ]}
      </TabbedShowLayout>
    </Show>
  );
};
