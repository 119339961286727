import {
  BulkDeleteButton,
  DeleteWithConfirmButton,
  DeleteWithConfirmButtonProps,
} from "react-admin";

export const FranquiciaBulkDeleteButton = () => <BulkDeleteButton />;

export const FranquiciaDeleteButton = () => (
  <DeleteWithConfirmButton {...deleteButtonProps} />
);

const deleteButtonProps: DeleteWithConfirmButtonProps = {
  mutationMode: "pessimistic",
  confirmTitle: "Eliminar Franquicias",
  confirmContent:
    "¿Seguro que quiere eliminar esta franquicia? ¡Se eliminarán todas las clinicas, usuarios y contenidos multimedias asociado a esta franquicia!",
};
