import { apolloClient } from "../apolloClient";
import { gql } from "@apollo/client";
import { GetSemanas } from "./micDataProvider";

const getWeeksAvailables = async (userId: string) => {
  const result = await apolloClient.query<GetSemanas>({
    fetchPolicy: "network-only",
    variables: { userId: userId },
    query: gql`
      query getWeeks($userId: uuid!) {
        Multimedia(distinct_on: semana, where: { userId: { _eq: $userId } }) {
          semana
        }
      }
    `,
  });
  const a = result.data.Multimedia.map((e) => e.semana);
  return { data: a };
};

export default getWeeksAvailables;
