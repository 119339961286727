import { Labeled, TextField, TextInput, useRecordContext } from "react-admin";

export const BebeNombreInput = (props: any) => {
  const record = useRecordContext();
  if (record && record.role !== "USER") return <></>;

  return <TextInput source="nombreBebe" />;
};

export const BebeNombreField = () => {
  const record = useRecordContext();

  if (record && record.role !== "USER") return <></>;

  return (
    <Labeled>
      <TextField source="nombreBebe" />
    </Labeled>
  );
};
