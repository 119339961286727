import { GET_LIST, GET_ONE } from "react-admin";
import { BuildFields, buildFields } from "ra-data-hasura";
import { gql } from "@apollo/client";

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL queryAst} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) =>
  queryAst.definitions[0].selectionSet.selections;

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES: any = {
  Notificacion: {
    [GET_ONE]: gql`
      {
        titulo
        cuerpo
        details
        id
        imageUrl
        createdAt
        updatedAt
        linkUrl
        FranquiciaNotificacions {
          Franquicia {
            id
            nombre
          }
        }
        ClinicaNotificacions {
          Clinica {
            id
            nombre
          }
        }
        UserNotificacions {
          User {
            id
            firstname
            lastname
          }
        }
      }
    `,
  },
};

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields: BuildFields = (type, fetchType) => {
  const resourceName: string = type.name;

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (fetchType && resourceCustomQueries?.[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType);
};

export default customBuildFields;
