import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";
import { useListContext, DeleteButton, useRecordContext } from "react-admin";
import React from "react";
import useGetWeeks from "../../../../providers/utils/useGetWeeks";

export const ImageGallery = React.memo(() => {
  const { data, setFilters, filterValues } = useListContext<Media>();
  const { id } = useRecordContext();
  const weeks = useGetWeeks(id as string);

  console.log("filter: ", filterValues);
  if (!data) return <span>Loading...</span>;

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Select
          defaultValue={"remove"}
          autoWidth
          onChange={({ target }) =>
            target.value === "remove"
              ? setFilters({ ...filterValues, semana: undefined }, {})
              : setFilters({ ...filterValues, semana: target.value }, {})
          }
        >
          <MenuItem value="remove">Semana de embarazo</MenuItem>
          {weeks.map((e) => (
            <MenuItem value={e}>{e}</MenuItem>
          ))}
        </Select>
        <RadioGroup row onChange={handleRadio}>
          <FormControlLabel control={<Radio />} label="Videos" value="1" />
          <FormControlLabel control={<Radio />} label="Fotos" value="0" />
          <FormControlLabel control={<Radio />} label="Imagen 8K" value="3" />
          <FormControlLabel control={<Radio />} label="Latido" value="4" />
          <FormControlLabel
            control={<Radio />}
            label="Video Premium"
            value="2"
          />
          <FormControlLabel
            control={<Radio />}
            label="Todos"
            value="undefined"
          />
        </RadioGroup>
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {data.map((media: Media) => (
          <Stack>
            {media.category !== 0 && media.category !== 3 ? (
              <video
                width="120"
                height="120"
                controls={false}
                onClick={() => window.open(media.url)}
                preload="metadata"
              >
                <source src={media.url} type="video/mp4" />
              </video>
            ) : (
              <img
                onClick={() => window.open(media.url)}
                alt={media.id}
                width={120}
                height={120}
                src={media.url}
              />
            )}
            <DeleteButton
              sx={{ width: 120 }}
              label={""}
              resource="Multimedia"
              redirect={false}
              confirmTitle="Eliminar multimedia"
              mutationMode="undoable"
              record={media}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );

  function handleRadio(v: React.ChangeEvent<HTMLInputElement>): void {
    return setFilters(
      {
        ...filterValues,
        category: v.target.value !== "undefined" ? v.target.value : undefined,
      },
      {}
    );
  }
});

type Media = {
  category: number;
  url: string;
  id: string;
  semana: number;
};
