import { useRecordContext } from "react-admin";

export const LimitedLenghtTextField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  return (
    <span>
      {record &&
        String(record[source])
          .replace(/<\/?[^>]+(>|$)/g, " ")
          .substring(0, 455) + "..."}
    </span>
  );
};
