import { gql } from "@apollo/client";
import { AuthProvider } from "react-admin";
import { apolloClient } from "./apolloClient";

const authProvider: AuthProvider = {
  login: async ({ username, password }: any) => {
    try {
      const request = await apolloClient.mutate({
        mutation: gql`
          mutation login($data: LoginInput!) {
            login(data: $data) {
              accessToken
              refreshToken
              user {
                id
                email
                role
                clinicaId
                franquiciaId
              }
            }
          }
        `,
        variables: {
          data: { email: username, password: password },
        },
      });

      //inMemoryJwt.setRefreshTokenEndpoint("");
      if (request.errors) {
        throw new Error(JSON.stringify(request.errors));
      }
      localStorage.setItem("accessToken", request.data.login.accessToken);
      localStorage.setItem("refreshToken", request.data.login.refreshToken);

      const { email, id, role, clinicaId, franquiciaId } =
        request.data.login.user;

      if (role === "USER") {
        throw new Error(":/");
      }

      localStorage.setItem(
        "auth",
        JSON.stringify({ email, id, role, clinicaId, franquiciaId })
      );
      return Promise.resolve();
    } catch (ex) {
      return Promise.reject();
    }
  },
  logout: async () => {
    localStorage.clear();
    apolloClient.clearStore();

    return Promise.resolve();
  },

  checkAuth: async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (accessToken && refreshToken) {
      const { exp } = parseJwt(accessToken);

      if (exp * 1000 > new Date().getTime() + 3600 * 4) {
        return Promise.resolve();
      } else {
        const request = await apolloClient.mutate({
          mutation: gql`
            mutation refreshToken($token: JWT!) {
              refreshToken(token: $token) {
                accessToken
                refreshToken
              }
            }
          `,
          variables: {
            token: refreshToken,
          },
        });

        if (request.data.refreshToken.refreshToken) {
          localStorage.setItem(
            "accessToken",
            request.data.refreshToken.accessToken
          );
          //TODO: Implementar el refreshtoken en una query httponly
          localStorage.setItem(
            "refreshToken",
            request.data.refreshToken.refreshToken
          );
          console.log("REFRESHED TOKEN");
          Promise.resolve();
        } else {
          Promise.reject();
        }
      }
    }
    return Promise.reject();
  },
  getIdentity: async () => {
    try {
      const { id, email, role, clinicaId, franquiciaId } = JSON.parse(
        localStorage.getItem("auth")!
      );

      return Promise.resolve({
        id,
        fullName: email,
        role,
        email,
        clinicaId,
        franquiciaId,
        avatar:
          "https://pbs.twimg.com/profile_images/831173492968140804/43M7c5j__400x400.jpg",
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  checkError: (error: any) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    try {
      const { role } = JSON.parse(localStorage.getItem("auth")!);

      return Promise.resolve(role);
    } catch (ex) {
      return Promise.reject();
    }
  },
};

export default authProvider;

export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
