import { Datagrid, List, TextField } from "react-admin";
import PreviewImageField from "../../forms/PreviewImageField";
import { FranquiciaBulkDeleteButton } from "./components/FranquiciaDeleteButton";

export const FranquiciaList = () => (
  <List>
    <Datagrid
      rowClick="show"
      bulkActionButtons={<FranquiciaBulkDeleteButton />}
    >
      <TextField source="nombre" />
      <PreviewImageField width={100} height={100} source="logoUrl" />
    </Datagrid>
  </List>
);
