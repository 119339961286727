import {
  ChipField,
  DateField,
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  WithRecord,
} from "react-admin";
import UserShowActions from "./components/show/UserShowActions";
import { ClinicaField } from "./components/fields/ClinicaField";
import { VFormRow } from "../../forms/VFormRow";
import { UserMediaUpload } from "./components/show/UserMediaUpload";
import { FranquiciaField } from "./components/fields/FranquiciaField";
import { ReglaField } from "./components/fields/ReglaField";
import { BebeNombreField } from "./components/fields/BebeNombreField";
import { Box } from "@mui/material";
import { FinServicioField } from "./components/fields/FinServicioInput";

export const UserShow = (props: any) => (
  <Show actions={<UserShowActions {...props} />}>
    <WithRecord
      render={(user) => (
        <TabbedShowLayout>
          <Tab label="Usuario" sx={{ maxWidth: 600 }}>
            <Box maxWidth={1200}>
              <VFormRow labeled text="Identidad">
                <EmailField source="email" />
                <TextField source="firstname" label="Nombre" />
                <TextField source="lastname" label="Apellidos" />
              </VFormRow>
              <VFormRow labeled>
                <ChipField source="role" />
                <ClinicaField />
                <FranquiciaField />
              </VFormRow>
              <VFormRow>
                <ReglaField />
                <BebeNombreField />
              </VFormRow>
              <VFormRow labeled text="Metadatos">
                <TextField source="id" />
                <DateField label="Fecha de creación" source="createdAt" />
              </VFormRow>
              <FinServicioField />
            </Box>
          </Tab>
          {user.role === "USER" && (
            <Tab label="Media">
              <UserMediaUpload user={user as any} />
            </Tab>
          )}
        </TabbedShowLayout>
      )}
    />
  </Show>
);
