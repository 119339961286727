import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  QuoteButtons,
  ClearButtons,
  ColorButtons,
  RichTextInputProps,
  AlignmentButtons,
} from "ra-input-rich-text";

export const RichTextEditor = (props: Partial<RichTextInputProps>) => (
  <RichTextInput
    source="mensaje"
    label="Cuerpo"
    {...props}
    toolbar={
      <RichTextInputToolbar>
        <LevelSelect />
        <FormatButtons />
        <QuoteButtons />
        <ClearButtons />
        <ColorButtons />
        <AlignmentButtons />
      </RichTextInputToolbar>
    }
  />
);
