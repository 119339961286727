import { CreateResult } from "react-admin";

export default async function imageUploaderProvider(params: any) {
  console.log("params: ", params);
  const paramsCopy = { ...params };
  for (const key of Object.keys(params.data)) {
    if (paramsCopy.data[key] && paramsCopy.data[key].rawFile instanceof File) {
      const file: File = paramsCopy.data[key].rawFile;
      const form = new FormData();
      form.append("file", file);

      const a = await fetch(`${process.env.REACT_APP_NEST_API}/s3/upload`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const b = await a.json();

      delete paramsCopy.data[key];

      paramsCopy.data[key] = b.Location;
    }
  }
  return paramsCopy;
}

export async function mediaUploader(
  resource: any,
  params: any
): Promise<CreateResult<any>> {
  const form = new FormData();
  form.append("categoryImage", params.data.categoryImage);
  form.append("categoryVideo", params.data.categoryVideo);
  params.data.images.forEach((file: { rawFile: File }) => {
    form.append("files", file.rawFile);
  });
  const a = await fetch(
    `${process.env.REACT_APP_NEST_API}/s3/uploadMedia/${params.data.id}/${params.data.semana}`,
    {
      method: "POST",
      body: form,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
  const b = await a.json();
  if (b.count <= 0) {
    throw new Error("Error, no se ha subido nada");
  }
  return { data: { id: Math.floor(Math.random() * 1000) } };
}
