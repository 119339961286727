import { gql } from "@apollo/client";
import { apolloClient } from "../apolloClient";

export default async function passwordUpdater(resource: any, params: any) {
  const result = await apolloClient.mutate({
    mutation: gql`
      mutation updatePassword($password: String!, $userId: String!) {
        updatePassword(password: $password, userId: $userId) {
          id
        }
      }
    `,
    variables: { password: params.data.password, userId: params.id },
  });

  if (result.data.updatePassword.id === params.id) {
    return { data: params };
  } else {
    throw new Error("No se ha podido completar la operacion");
  }
}
