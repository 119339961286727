import {
  ImageInput,
  useRefresh,
  useNotify,
  CreateBase,
  SimpleForm,
  NumberInput,
  Button,
  RadioButtonGroupInput,
} from "react-admin";
import { FileRejection } from "react-dropzone";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import PreviewImageField from "../../../../forms/PreviewImageField";
import { Fragment, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function MediaUploadDialog({ user }: { user: { id: string } }) {
  const [isOpen, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Fragment>
      <Stack width={270}>
        <Button
          variant="contained"
          label="Subir contenido"
          onClick={() => setOpen(true)}
        >
          <FileUploadIcon />
        </Button>
      </Stack>
      <br />
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>Subir contenido</DialogTitle>
        <DialogContent>
          <CreateBase
            redirect={"#"}
            resource="Multimedia"
            mutationOptions={{
              onSuccess: () => {
                notify("Archivos subidos con éxito");
                refresh();
                setOpen(false);
              },
            }}
            transform={(data) => {
              data.id = user.id;
              return data;
            }}
          >
            <SimpleForm>
              <NumberInput
                source="semana"
                label="Semana de embarazo"
                required
              />
              <RadioButtonGroupInput
                source="categoryVideo"
                label="Categoria de video"
                defaultValue={1}
                choices={[
                  { id: "1", name: "Video corto" },
                  { id: "2", name: "Video premium" },
                  { id: "4", name: "Latido" },
                ]}
              />
              <RadioButtonGroupInput
                source="categoryImage"
                label="Categoria de Imagen"
                defaultValue={0}
                choices={[
                  { id: "0", name: "Foto" },
                  { id: "3", name: "Imagen 8K" },
                ]}
              />
              <ImageInput
                source="images"
                label="Imagenes y videos"
                options={{
                  onDropRejected: (files: FileRejection[]) => {
                    notify(
                      "Error:" +
                        files.map((f) =>
                          f.errors.map((e) => `${f.file.name}: ${e.message}`)
                        ),
                      {
                        type: "error",
                      }
                    );
                  },
                }}
                accept="video/*,image/*"
                multiple
              >
                <PreviewImageField source="src" width={80} height={80} />
              </ImageInput>
            </SimpleForm>
          </CreateBase>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default MediaUploadDialog;
