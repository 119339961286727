// Initialize the dataProvider before rendering react-admin resources.
import { useState, useEffect } from "react";
import buildHasuraProvider from "ra-data-hasura";
import { Admin, Resource } from "react-admin";
import franquicia from "./sections/Franquicia";
import clinica from "./sections/Clinica";
import authProvider from "./providers/authProvider";
import { apolloClient } from "./providers/apolloClient";
import calculadoraEmbarazo from "./sections/CalculadoraEmbarazo";
import micDataProvider from "./providers/dataProvider/micDataProvider";
import User from "./sections/User";
import { lightTheme } from "./layout/theme";
import MyLayout from "./layout/Layout";

import polyglotI18nProvider from "ra-i18n-polyglot";
import { Login } from "./layout/Login";
import Notificaciones from "./sections/Notifications";
import customBuildFields from "./providers/dataProvider/custom-build-fields";
import { Dashboard } from "./sections/Dashboard";

//@ts-ignore
import spanishMessages from "@blackbox-vision/ra-language-spanish";

const i18nProvider = polyglotI18nProvider(
  () => ({
    ra: {
      ...spanishMessages.ra,
      tiptap: {
        heading1: "Titulo 1",
        heading2: "Titulo 2",
        heading3: "Titulo 3",
        heading4: "Titulo 4",
        heading5: "Titulo 5",
        heading6: "Titulo 6",
      },
    },
  }),
  "es",
  {
    allowMissing: true,
  }
);

const App = () => {
  const [dataProvider, setDataProvider] = useState<any>(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider(
        {
          client: apolloClient,
        },
        { buildFields: customBuildFields }
      );
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  const myDataProvider = micDataProvider(dataProvider);

  return (
    <Admin
      dataProvider={myDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={MyLayout}
      requireAuth
      theme={lightTheme}
      loginPage={Login}
      dashboard={Dashboard}
    >
      {(permissions: string) => [
        <Resource
          {...clinica(
            permissions === "SUPERADMIN" || permissions === "FRANQUICIAADMIN"
          )}
        />,
        <Resource {...franquicia(permissions)} />,
        <Resource {...User} />,
        <Resource {...calculadoraEmbarazo(permissions)} />,
        <Resource {...Notificaciones()} />,
      ]}
    </Admin>
  );
};

export default App;
