import {
  Datagrid,
  DateField,
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  ResourceProps,
  Create,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  regex,
  required,
  EditButton,
  ShowButton,
  Show,
  SimpleShowLayout,
  ArrayField,
  useListContext,
  usePermissions,
  RichTextField,
} from "react-admin";
import Notifications from "@mui/icons-material/Notifications";
import { VFormRow } from "../forms/VFormRow";
import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PreviewImageField from "../forms/PreviewImageField";
import { RichTextEditor } from "../forms/RichTextEditor";
import { Stack } from "@mui/material";

export const NotificacionEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <Box maxWidth={1200} width={"100%"}>
        <Typography variant="h6" gutterBottom>
          Mensaje
        </Typography>
        La información que aparecerá en la notificación push recibida en el
        dispositivo
        <VFormRow>
          <TextInput
            multiline
            fullWidth
            validate={required()}
            inputProps={{ maxLength: 50 }}
            source="titulo"
          />
          <TextInput
            inputProps={{ maxLength: 385 }}
            fullWidth
            validate={required()}
            minRows={5}
            maxRows={8}
            multiline
            source="cuerpo"
            label="Cuerpo"
          />
        </VFormRow>
      </Box>

      <Box maxWidth={1200} width={"100%"}>
        <Typography variant="h6" gutterBottom>
          Contenido
        </Typography>
        <Typography marginBottom={2}>
          Contenido que aparecerá dentro de la aplicación.
        </Typography>
        <ImageInput accept="image/*" source="imageUrl">
          <PreviewImageField source="src" width={120} height={120} />
        </ImageInput>
        <Stack>
          <TextInput
            source="linkUrl"
            validate={regex(
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
              "Introduce una URL válida"
            )}
            label="Enlace promoción"
          />
        </Stack>
        <RichTextEditor source="details" />
      </Box>
    </SimpleForm>
  </Edit>
);

export const NotificacionList = () => (
  <List>
    <Datagrid>
      <TextField source="titulo" />
      <TextField source="cuerpo" />
      <PreviewImageField source="imageUrl" />
      <DateField source="createdAt" label="Fecha envio" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export const NotificacionShow = () => (
  <Show>
    <SimpleShowLayout sx={{ maxWidth: 1600 }}>
      <VFormRow labeled text="Notificacion Push">
        <TextField source="titulo" />
        <TextField source="cuerpo" />
      </VFormRow>
      <VFormRow labeled text="Contenido">
        <RichTextField source="details" />
        <PreviewImageField
          width={220}
          height={220}
          source="imageUrl"
          objectFit="contain"
        />
      </VFormRow>
      <DateField source="createdAt" label="Fecha envio" />
      <Typography variant="h6" gutterBottom>
        Destinatario
      </Typography>
      <ArrayField
        fullWidth
        source="FranquiciaNotificacions"
        label="Franquicias"
      >
        <ChipArrayList render={(c) => c.Franquicia.nombre} />
      </ArrayField>
      <ArrayField source="ClinicaNotificacions" label="Clinicas">
        <ChipArrayList render={(c) => c.Clinica.nombre} />
      </ArrayField>
      <ArrayField source="UserNotificacions" label="Usuarios">
        <ChipArrayList
          render={(c) => `${c.User.firstname} ${c.User.lastname}`}
        />
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
export const ChipArrayList = ({ render }: { render: (c: any) => string }) => {
  const { data } = useListContext();
  console.log("data: ", data);
  return (
    <>
      {data.map((chip) =>
        Object.entries(chip).length > 0 ? (
          <Chip sx={{ marginX: 1 }} label={render(chip)} variant="outlined" />
        ) : (
          <Chip
            sx={{ marginX: 1 }}
            color="error"
            variant="outlined"
            label={"Sin permiso para ver"}
          />
        )
      )}
    </>
  );
};

const validateDestino =
  (mensaje: string) =>
  (_: any, { Clinicas, Franquicias, Usuarios }: any) => {
    if (Clinicas.length + (Franquicias ?? []).length + Usuarios.length === 0) {
      return mensaje;
    }
    return;
  };
export const NoticacionCreate = () => {
  const { permissions } = usePermissions();
  console.log("permissions: ", permissions);
  return (
    <Create>
      <TabbedForm>
        <FormTab label="Notificacion Push">
          <Box maxWidth={1200} width={"100%"}>
            <Typography variant="h6" gutterBottom>
              Mensaje
            </Typography>
            La información que aparecerá en la notificación push recibida en el
            dispositivo
            <VFormRow>
              <TextInput
                multiline
                fullWidth
                validate={required()}
                inputProps={{ maxLength: 50 }}
                source="titulo"
              />
              <TextInput
                inputProps={{ maxLength: 385 }}
                fullWidth
                validate={required()}
                minRows={5}
                maxRows={8}
                multiline
                source="body"
                label="Cuerpo"
              />
            </VFormRow>
            <VFormRow text="Destino">
              {(permissions === "SUPERADMIN" ||
                permissions === "FRANQUICIAADMIN") && (
                <ReferenceArrayInput
                  source="Franquicias"
                  reference="Franquicia"
                >
                  <AutocompleteArrayInput
                    validate={validateDestino(
                      "Introduce al menos un usuario, clinica o franquicia"
                    )}
                    filterToQuery={(searchText) => ({
                      "nombre@_ilike": searchText,
                    })}
                    fullWidth
                    blurOnSelect={false}
                    disableCloseOnSelect={true}
                    optionText="nombre"
                  />
                </ReferenceArrayInput>
              )}
              <ReferenceArrayInput source="Clinicas" reference="Clinica">
                <AutocompleteArrayInput
                  validate={validateDestino(" ")}
                  filterToQuery={(searchText) => ({
                    "nombre@_ilike": searchText,
                  })}
                  blurOnSelect={false}
                  disableCloseOnSelect={true}
                  optionText="nombre"
                />
              </ReferenceArrayInput>
              <ReferenceArrayInput source="Usuarios" reference="User">
                <AutocompleteArrayInput
                  validate={validateDestino(" ")}
                  filterToQuery={(searchText) => ({
                    "email,firstname,lastname": searchText,
                  })}
                  blurOnSelect={false}
                  disableCloseOnSelect={true}
                  optionText={(v) => `${v.firstname} ${v.lastname}`}
                />
              </ReferenceArrayInput>
            </VFormRow>
          </Box>
        </FormTab>

        <FormTab label="Contenido">
          <Box maxWidth={1200} width={"100%"}>
            <Typography variant="h6" gutterBottom>
              Contenido
            </Typography>
            <Typography marginBottom={2}>
              Contenido que aparecerá dentro de la aplicación.
            </Typography>
            <ImageInput source="imagen">
              <PreviewImageField width={120} height={120} source="src" />
            </ImageInput>
            <Stack>
              <TextInput
                source="url"
                validate={regex(
                  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                  "Introduce una URL válida"
                )}
                label="Enlace promoción"
              />
              <RichTextEditor source="details" />
            </Stack>
          </Box>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
const Notificaciones = (): ResourceProps => ({
  name: "Notificacion",
  options: { label: "Notificaciones" },
  list: NotificacionList,
  edit: NotificacionEdit,
  icon: Notifications,
  create: NoticacionCreate,
  show: NotificacionShow,
});

export default Notificaciones;
