import { DataProvider } from "react-admin";
import getWeeksAvailables from "./getWeeksAvailables";
import imageUploaderProvider, { mediaUploader } from "./imageUploader";
import passwordUpdater from "./passwordUpdater";
import sendNotification from "./sendNotificationProvider";

const micDataProvider: (dataProvider: DataProvider) => DataProvider = (
  provider
) => ({
  ...provider,
  getWeeksAvailables,
  create: async (resource: any, params: any) => {
    if (resource === "Notificacion")
      return await sendNotification(
        resource,
        await imageUploaderProvider(params)
      );
    if (resource === "Multimedia") return await mediaUploader(resource, params);
    if (resource === "User") {
      const a = await provider.create(resource, params);
      await passwordUpdater(resource, {
        data: { password: params.data.password },
        id: a.data.id,
      });
      return a;
    }

    return await provider.create(resource, await imageUploaderProvider(params));
  },
  update: async (resource: any, params: any) => {
    if (resource === "User" && params.data.password)
      return passwordUpdater(resource, { data: params.data, id: params.id });

    return await provider.update(resource, await imageUploaderProvider(params));
  },
});
export default micDataProvider;

export type GetSemanas = {
  Multimedia: { semana: number }[];
};
