import {
  ChipField,
  DateField,
  EmailField,
  List,
  ReferenceField,
  TextField,
  BooleanField,
  TopToolbar,
  CreateButton,
  SelectColumnsButton,
  ExportButton,
  DatagridConfigurable,
} from "react-admin";
import { UserFilterSidebar } from "./components/list/UserFilterSidebar";

export const UserList = () => (
  <List
    actions={<ListActions />}
    aside={<UserFilterSidebar />}
    filterDefaultValues={{ "enabled@_eq": "true" }}
  >
    <DatagridConfigurable rowClick="show" omit={["fechaFinServicio", "role"]}>
      <EmailField source="email" />
      <TextField source="firstname" label="Nombre" />
      <TextField source="lastname" label="Apellidos" />
      <ChipField source="role" label="Rol" />
      <DateField source="createdAt" label="Fecha creación" />
      <DateField source="fechaFinServicio" label="Fin servicio" />
      <ReferenceField source="clinicaId" link="show" reference="Clinica">
        <TextField source="nombre" />
      </ReferenceField>
      <ReferenceField
        source="franquiciaId"
        link="show"
        reference="Franquicia"
        label="Administra"
      >
        <TextField source="nombre" />
      </ReferenceField>
      <BooleanField label="Activo" source="enabled" />
    </DatagridConfigurable>
  </List>
);

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);
