import { gql } from "@apollo/client";
import { apolloClient } from "../apolloClient";

export default async function sendNotification(_: any, params: any) {
  const result = await apolloClient.mutate({
    mutation: gql`
      mutation sendNotification($notificacion: SendNotificacionInput!) {
        sendNotificacion(createNotificacionesInput: $notificacion) {
          result
        }
      }
    `,
    variables: {
      notificacion: {
        clinicas: params.data.Clinicas,
        franquicias: params.data.Franquicias,
        users: params.data.Usuarios,
        payload: {
          body: params.data.body,
          imageUrl: params.data.imagen,
          title: params.data.titulo,
          url: params.data.url,
          details: params.data.details,
        },
      } as SendNotificacionInput,
    },
  });
  console.log("result: ", result.data.sendNotificacion.result === "OK");

  if (result.data.sendNotificacion.result === "OK") {
    return { data: { id: params.result, ...params } };
  } else {
    throw new Error("No se ha podido completar la operacion");
  }
}

type SendNotificacionInput = {
  payload: Payload;
  users?: string[];
  clinicas?: string[];
  franquicias?: string[];
};

type Payload = {
  title: string;
  body: string;
  url?: string;
  imageUrl?: string;
  details?: string;
};
