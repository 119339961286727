import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  Edit,
  FormTab,
  NumberInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import { VFormRow } from "../../forms/VFormRow";

//@ts-ignore
import { ColorInput } from "@palustris/react-admin-color-input";

import { ImageInputWithErrors, InputColorLabel } from "./FranquiciaCreate";

export const FranquiciaEdit = () => (
  <Edit
    title="Crear una nueva franquicia"
    redirect="show"
    mutationMode="pessimistic"
  >
    <TabbedForm
      validate={(data) => {
        if (data.nombre === "")
          return { nombre: "Introduzca un nombre valido" };
        return {};
      }}
    >
      <FormTab label="Información">
        <TextInput source="nombre" />
        <NumberInput source="limiteClinicas" label="Límite de clínicas" />
      </FormTab>
      <FormTab label="Imágenes">
        <Box maxWidth={1200} width={"100%"}>
          <Typography variant="h6" gutterBottom>
            General
          </Typography>
          <ImageInputWithErrors
            source="logoUrl"
            label="Logo de la franquicia"
          />
          <VFormRow text="Iconos Aplicación">
            <ImageInputWithErrors
              source="appIconUrl"
              label="Logo aplicación móvil / splash (1024x1024)"
            />
            <ImageInputWithErrors
              source="appAdaptativeIcon"
              label="Icono adaptativo android api +25 (1024x1024)"
            />
          </VFormRow>
          <VFormRow text="Fondos">
            <ImageInputWithErrors
              source="backgroundImageUrl"
              label="Imagen de fondo en app (9:16)"
            />
            <ImageInputWithErrors
              source="backgroundImageCalculadoraUrl"
              label="Imagen de fondo en calculadora de embarazo (9:16)"
            />
          </VFormRow>
        </Box>
      </FormTab>
      <FormTab label="Visual">
        <Box maxWidth={1300}>
          <Typography variant="h6" gutterBottom>
            <SelectInput
              fullWidth
              source="rounded"
              validate={required()}
              label="Redondeado bordes"
              choices={[
                { id: "full", name: "Completo" },
                { id: "xl", name: "xl" },
                { id: "2xl", name: "2xl" },
                { id: "3xl", name: "3xl" },
                { id: "lg", name: "Largo" },
                { id: "md", name: "Medio" },
                { id: "none", name: "No" },
                { id: "sm", name: "Pequeño" },
                { id: "xs", name: "xs" },
              ]}
            />
          </Typography>
          <VFormRow text="Colores">
            <ColorInput
              label={<InputColorLabel>Principal 50</InputColorLabel>}
              source="primary50"
              picker="Sketch"
              validate={required()}
            />
            <ColorInput
              label={<InputColorLabel>Principal 100</InputColorLabel>}
              source="primary100"
              picker="Sketch"
              validate={required()}
            />
          </VFormRow>
          <VFormRow>
            <ColorInput
              label={<InputColorLabel>Principal 200</InputColorLabel>}
              source="primary200"
              picker="Sketch"
              validate={required()}
            />
            <ColorInput
              label={<InputColorLabel>Principal 300</InputColorLabel>}
              source="primary300"
              picker="Sketch"
              validate={required()}
            />
          </VFormRow>
          <VFormRow>
            <ColorInput
              label={<InputColorLabel>Principal 400</InputColorLabel>}
              source="primary400"
              picker="Sketch"
              validate={required()}
            />
            <ColorInput
              label={<InputColorLabel>Principal 500</InputColorLabel>}
              source="primary500"
              picker="Sketch"
              validate={required()}
            />
          </VFormRow>
          <VFormRow>
            <ColorInput
              label={<InputColorLabel>Principal 600</InputColorLabel>}
              source="primary600"
              picker="Sketch"
              validate={required()}
            />
            <ColorInput
              label={<InputColorLabel>Principal 700</InputColorLabel>}
              source="primary700"
              picker="Sketch"
              validate={required()}
            />
          </VFormRow>
          <VFormRow>
            <ColorInput
              label={<InputColorLabel>Principal 800</InputColorLabel>}
              source="primary800"
              picker="Sketch"
              validate={required()}
            />
            <ColorInput
              label={<InputColorLabel>Principal 900</InputColorLabel>}
              source="primary900"
              picker="Sketch"
              validate={required()}
            />
          </VFormRow>
        </Box>
      </FormTab>
    </TabbedForm>
  </Edit>
);
