import { ResourceProps, Show } from "react-admin";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import { FranquiciaList } from "./FranquiciaList";
import { FranquiciaCreate } from "./FranquiciaCreate";
import { FranquiciaEdit } from "./FranquiciaEdit";
import { FranquiciaSingleShow } from "./components/FranquiciaSingleShow";
import { FranquiciaShow } from "./FranquiciaShow";

const franquicia = (role: string): ResourceProps => ({
  name: "Franquicia",
  list: role === "SUPERADMIN" ? FranquiciaList : undefined,
  create: role === "SUPERADMIN" ? FranquiciaCreate : undefined,
  show:
    role === "SUPERADMIN" || role === "FRANQUICIAADMIN"
      ? FranquiciaShow
      : () => (
          <Show>
            <FranquiciaSingleShow />
          </Show>
        ),
  icon: BrandingWatermarkIcon,
  edit:
    role === "SUPERADMIN" || role === "FRANQUICIAADMIN"
      ? FranquiciaEdit
      : undefined,
});

export default franquicia;
