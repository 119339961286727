import { Button, RaRecord, useRecordContext, useRedirect } from "react-admin";
import AddIcon from "@mui/icons-material/Add";

export const CreateWithDefaultValuesButton = ({
  resource,
  defaultValues,
}: CreatWithDefaultValuesButtonProps) => {
  const redirect = useRedirect();
  const record = useRecordContext();

  return (
    <Button
      variant="text"
      color="primary"
      label="Crear"
      onClick={() => {
        redirect(false);
        redirect(
          "create",
          resource,
          undefined,
          {},
          { record: defaultValues(record) }
        );
      }}
    >
      <AddIcon />
    </Button>
  );
};

type CreatWithDefaultValuesButtonProps = {
  resource: string;
  defaultValues: (context: RaRecord) => any;
};
