import { Box, Typography } from "@mui/material";
import { Labeled } from "react-admin";

export const VFormRow = ({
  children,
  text,
  labeled = false,
}: {
  children: any[];
  text?: string;
  labeled?: boolean;
}) => (
  <>
    {text && (
      <Typography variant="h6" gutterBottom>
        {text}
      </Typography>
    )}
    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
      {children.map((el) => (
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          {labeled ? <Labeled>{el}</Labeled> : el}
        </Box>
      ))}
    </Box>
  </>
);
