import { ResourceProps } from "react-admin";

import { UserList } from "./UserList";
import { UserEdit } from "./UserEdit";
import { UserCreate } from "./UserCreate";
import { UserShow } from "./UserShow";

const User: ResourceProps = {
  name: "User",
  list: UserList,
  edit: UserEdit,
  show: UserShow,
  create: UserCreate,
  options: { label: "Usuario" },
};

export default User;
