import {
  Labeled,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useRecordContext,
} from "react-admin";

export const ClinicaInput = (props: any) => {
  const record = useRecordContext();
  if (
    record &&
    (record.role === "SUPERADMIN" || record.role === "FRANQUICIAADMIN")
  )
    return <></>;
  return (
    <ReferenceInput source="clinicaId" reference="Clinica" required>
      <SelectInput {...props} optionText="nombre" required />
    </ReferenceInput>
  );
};

export const ClinicaField = () => {
  const record = useRecordContext();
  if (
    record &&
    (record.role === "SUPERADMIN" || record.role === "FRANQUICIAADMIN")
  )
    return <></>;
  return (
    <Labeled>
      <ReferenceField source="clinicaId" reference="Clinica" link="show">
        <TextField source="nombre" />
      </ReferenceField>
    </Labeled>
  );
};
