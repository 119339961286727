import { Box, Card, CardContent } from "@mui/material";
import {
  Count,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  usePermissions,
} from "react-admin";
import BadgeIcon from "@mui/icons-material/Badge";
import StoreIcon from "@mui/icons-material/Store";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { FinServicioFilter } from "./FinServicioFilter";

export const UserFilterSidebar = () => {
  const { permissions } = usePermissions();
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
        order: -1,
        flex: "0 0 15em",
        mr: 2,
        mt: 8,
        alignSelf: "flex-start",
      }}
    >
      <Card>
        <CardContent>
          <FilterLiveSearch
            variant="outlined"
            source="email,firstname,lastname"
          />
          {(permissions === "SUPERADMIN" ||
            permissions === "FRANQUICIAADMIN") && (
            <>
              <FilterList label="Rol" icon={<BadgeIcon />}>
                {permissions === "SUPERADMIN" && (
                  <FilterListItem
                    label="Super Administrador"
                    value={{ "role@_eq": "SUPERADMIN" }}
                  />
                )}
                <FilterListItem
                  label="Admin franquicia"
                  value={{ "role@_eq": "FRANQUICIAADMIN" }}
                />
                <FilterListItem
                  label="Admin clinica"
                  value={{ "role@_eq": "ADMIN" }}
                />
                <FilterListItem
                  label="Usuario"
                  value={{ "role@_eq": "USER" }}
                />
              </FilterList>
              <FilterList label="Clinica" icon={<StoreIcon />}>
                <FilterLiveSearch
                  variant="outlined"
                  source="Clinica#nombre@_ilike"
                />
              </FilterList>
            </>
          )}
          <FilterList label="Estado" icon={<PersonOffIcon />}>
            <FilterListItem label="Activo" value={{ "enabled@_eq": "true" }} />
            <FilterListItem
              label="Deshabilitado"
              value={{ "enabled@_eq": "false" }}
            ></FilterListItem>
          </FilterList>

          <FinServicioFilter />
        </CardContent>
      </Card>
    </Box>
  );
};
