import {
  DateField,
  DateInput,
  Labeled,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { ROL } from "../../../../providers/utils/constants";

export const FinServicioInput = (_props: any) => {
  const { permissions: rol } = usePermissions();

  const record = useRecordContext();

  // Solo los clientes se van a borrar en la finalización del servicio
  if (record && record.role !== "USER") return <></>;

  const canEdit = rol === ROL.SUPERADMIN || rol === ROL.FRANQUICIAADMIN;

  return <DateInput source="fechaFinServicio" disabled={!canEdit} />;
};

export const FinServicioField = () => {
  const record = useRecordContext();
  if (record && record.role !== "USER") return <></>;

  return (
    <Labeled>
      <DateField source="fechaFinServicio" />
    </Labeled>
  );
};
