// in src/Dashboard.tsx
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GroupsIcon from "@mui/icons-material/Groups";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";

import { Fragment } from "react";
import { Button, ListButton } from "react-admin";
import { Link } from "react-router-dom";
import Notificaciones from "../Notifications";
import User from "../User";
import {
  CountUsuariosExpirar,
  finServicioFilterUrl,
} from "../User/components/list/FinServicioFilter";

export const Dashboard = () => {
  return (
    <Fragment>
      <Card sx={{ marginY: 2, maxWidth: "640px" }}>
        <CardHeader
          title={`Bienvenido a App Ibérica v${process.env.REACT_APP_VERSION}-${process.env.NODE_ENV}`}
        />

        {/*Introducción y mensaje de bienvenida*/}
        <CardContent>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            Bienvenido a App Ibérica, la herramienta de gestión multimedia
            diseñada específicamente para facilitar el proceso de intercambio de
            imágenes y videos de ecografías entre los profesionales clínicos y
            sus clientes.
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: -2 }}>
            A continuación se muestra un listado de acciones rápidas.
          </Typography>
        </CardContent>
        {/*{------}*/}

        <CardActions>
          {/*Nuevos usuarios*/}
          <Button
            component={Link}
            to={
              User.name +
              `?filter=${JSON.stringify({
                "enabled@_eq": "true",
                "role@_eq": "USER",
              })}&order=DESC&sort=createdAt`
            }
            label="Nuevos usuarios"
          >
            <GroupsIcon />
          </Button>

          {/*Notificaciones*/}
          <ListButton
            icon={<NotificationsIcon />}
            label="Notificaciones"
            resource={Notificaciones().name}
          />

          {/*Usuarios apunto de expirar*/}
          <Grid>
            <Button
              component={Link}
              sx={{ color: "red" }}
              to={User.name + finServicioFilterUrl()}
              label="Usuarios apunto de expirar"
            >
              <DeleteForeverIcon />
            </Button>
            <Chip
              sx={{
                position: "relative",
                top: -15,
                left: -10,
              }}
              variant="outlined"
              color="error"
              size="small"
              label={<CountUsuariosExpirar />}
            />
          </Grid>
        </CardActions>
      </Card>
    </Fragment>
  );
};
