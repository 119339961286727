import {
  Create,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  TextInput,
  DateInput,
  usePermissions,
  PasswordInput,
  minLength,
  email,
} from "react-admin";
import { ClinicaInput } from "./components/fields/ClinicaField";
import { VFormRow } from "../../forms/VFormRow";
import { FranquiciaInput } from "./components/fields/FranquiciaField";

export const UserCreate = () => {
  const { permissions } = usePermissions();

  console.log("permissions: ", permissions);
  return (
    <Create
      redirect="show"
      transform={(data: any) => {
        if (
          data.role === "ADMIN" ||
          data.role === "USER" ||
          data.role === "SUPERADMIN"
        ) {
          delete data.franquiciaId;
        }
        if (data.role === "FRANQUICIAADMIN" || data.role === "SUPERADMIN") {
          delete data.clinicaId;
        }
        data.email = data.email.trim();
        return data;
      }}
    >
      <SimpleForm sx={{ maxWidth: 500 }}>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <VFormRow text="Identidad">
                <TextInput label="Nombre" source="firstname" required />
                <TextInput label="Apellidos" source="lastname" required />
              </VFormRow>
              <TextInput
                source="email"
                required
                validate={email("Introduce un email válido")}
              />
              <PasswordInput
                source="password"
                label="Contraseña"
                required
                validate={[
                  minLength(
                    8,
                    "La contraseña tiene que tener mínimo 8 caracteres"
                  ),
                ]}
              />
              {(permissions === "SUPERADMIN" ||
                permissions === "FRANQUICIAADMIN") && (
                <VFormRow text="Permisos">
                  <SelectInput
                    required
                    sx={{ maxWidth: "100%" }}
                    source="role"
                    choices={[
                      ...(permissions === "SUPERADMIN"
                        ? [{ id: "SUPERADMIN", name: "Super Administrador" }]
                        : []),
                      {
                        id: "FRANQUICIAADMIN",
                        name: "Administrador de franquicia",
                      },
                      { id: "ADMIN", name: "Administrador de clínica" },
                      { id: "USER", name: "Usuario cliente" },
                    ]}
                  />
                  {(formData.role === "ADMIN" || formData.role === "USER") && (
                    <ClinicaInput sx={{ maxWidth: "300px" }} />
                  )}
                  {formData.role === "FRANQUICIAADMIN" && <FranquiciaInput />}
                </VFormRow>
              )}

              {(formData.role === "USER" || permissions === "ADMIN") && (
                <VFormRow text="Embarazo">
                  <DateInput source="ultimaRegla" />
                  <TextInput source="nombreBebe" />
                </VFormRow>
              )}
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
