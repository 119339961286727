import { SimpleShowLayout, TextField } from "react-admin";
import PreviewImageField from "../../../forms/PreviewImageField";
import { Grid } from "@mui/material";

export const FranquiciaSingleShow = () => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="nombre" />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={6}>
      <SimpleShowLayout>
        <PreviewImageField
          width={200}
          height={200}
          source="logoUrl"
          title="Imagen"
        />
      </SimpleShowLayout>
    </Grid>
  </Grid>
);
