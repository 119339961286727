import { Count, FilterList, FilterListItem } from "react-admin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

/**
 * Filtra el campo fechaFinServicio por treinta dias posteriores a la fecha
 * actual
 */
function getFiltro() {
  const treintaDias = new Date();
  treintaDias.setDate(treintaDias.getDate() + 30);

  return {
    "fechaFinServicio@_lte": treintaDias.toISOString().split("T")[0],
    "fechaFinServicio@_gte": new Date().toISOString().split("T")[0],
  };
}

/**
 * FilterList para el campo FechaFinServicio.
 * Añade un botón para filtrar por la fecha de fin de servicio próxima a los 30 dias siguientes
 */
export const FinServicioFilter = () => {
  return (
    <FilterList label="Fin servicio" icon={<DeleteForeverIcon />}>
      <FilterListItem
        label={
          <span>
            Próximos 30 dias (<CountUsuariosExpirar />)
          </span>
        }
        value={getFiltro()}
      />
    </FilterList>
  );
};

/**
 * Muestra la cuenta de usuarios pendientes de expirar
 */
export const CountUsuariosExpirar = () => (
  <Count
    resource="User"
    filter={{
      "enabled@_eq": "true",
      "role@_eq": "USER",
      ...getFiltro(),
    }}
  ></Count>
);

/**
 * Obtenemos el filtro con las siguientes propiedades
 * Activo: True  // Debe estar activo para poder estar dentro del rango de fin de servicio
 * Role: USER    // Los administradores no tienen fin de servicio
 */
export const finServicioFilterUrl = () =>
  `?filter=${JSON.stringify({
    "enabled@_eq": "true",
    "role@_eq": "USER",
    ...getFiltro(),
  })}&order=ASC&page=1&perPage=10&sort=fechaFinServicio`;
