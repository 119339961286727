import {
  RaRecord,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useRedirect,
} from "react-admin";
import { Stack } from "@mui/material";

export const CreateAdminToolbar = ({
  values,
  ...toolbarprops
}: CreateAdminToolbarProps & ToolbarProps) => {
  const redirect = useRedirect();
  return (
    <Toolbar {...toolbarprops}>
      <Stack direction="row" spacing={3}>
        <SaveButton />
        <SaveButton
          label="Guardar y crear administrador"
          variant="outlined"
          type="button"
          mutationOptions={{
            onSuccess: (data) => {
              redirect(
                "create",
                "User",
                undefined,
                {},
                { record: values(data) }
              );
            },
          }}
        />
      </Stack>
    </Toolbar>
  );
};

type CreateAdminToolbarProps = {
  values: (context: RaRecord) => any;
};
