import { ReactNode, createElement } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import DefaultIcon from "@mui/icons-material/ViewList";
import clsx from "clsx";
import {
  useResourceDefinitions,
  useGetResourceLabel,
  useCreatePath,
  useGetIdentity,
} from "ra-core";

import { useSidebarState } from "react-admin";
import { DashboardMenuItem } from "react-admin";
import { MenuItemLink } from "react-admin";
import StoreIcon from "@mui/icons-material/Store";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";

export const Menu = (props: MenuProps) => {
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();

  const {
    hasDashboard,
    dense,
    children = (
      <>
        {Object.keys(resources)
          .filter((name) => resources[name].hasList)
          .map((name) => (
            <MenuItemLink
              key={name}
              to={createPath({
                resource: name,
                type: "list",
              })}
              state={{ _scrollToTop: true }}
              primaryText={getResourceLabel(name, 2)}
              leftIcon={
                resources[name].icon ? (
                  createElement(resources[name].icon)
                ) : (
                  <DefaultIcon />
                )
              }
              dense={dense}
            />
          ))}
      </>
    ),
    className,
    ...rest
  } = props;

  const [open] = useSidebarState();

  const { identity } = useGetIdentity();
  return (
    <Root
      className={clsx(
        {
          [MenuClasses.open]: open,
          [MenuClasses.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {hasDashboard && <DashboardMenuItem dense={dense} />}
      {identity?.franquiciaId && (
        <MenuItemLink
          primaryText="Franquicia"
          to={createPath({
            resource: "Franquicia",
            type: "show",
            id: identity?.franquiciaId,
          })}
          {...{ leftIcon: <BrandingWatermarkIcon /> }}
        />
      )}
      {identity?.clinicaId && (
        <MenuItemLink
          primaryText="Clinica"
          to={createPath({
            resource: "Clinica",
            type: "show",
            id: identity?.clinicaId,
          })}
          {...{ leftIcon: <StoreIcon /> }}
        />
      )}
      {children}
    </Root>
  );
};

export interface MenuProps {
  children?: ReactNode;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
}

Menu.propTypes = {
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
};

const PREFIX = "RaMenu";

export const MenuClasses = {
  open: `${PREFIX}-open`,
  closed: `${PREFIX}-closed`,
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginTop: "0.5em",
  height: "100%",
  marginBottom: "1em",
  [theme.breakpoints.only("xs")]: {
    marginTop: 0,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
