import {
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  ExportButton,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  ResourceProps,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  useListContext,
  usePermissions,
} from "react-admin";

import StoreIcon from "@mui/icons-material/Store";
import { CreateAdminToolbar } from "../forms/CreateAdminToolbar";
import { VFormRow } from "../forms/VFormRow";

const ListActions = () => {
  const { total, isLoading } = useListContext();

  const { data, isLoading: isListLoading } = useGetList("Franquicia");

  if (isListLoading) {
    return (
      <TopToolbar>
        <ExportButton disabled={isLoading || total === 0} />{" "}
      </TopToolbar>
    );
  }

  const limiteClinicas = data![0].limiteClinicas;
  const canCreateMoreClinicas = limiteClinicas === 0 || total < limiteClinicas;

  return (
    <TopToolbar>
      <CreateButton disabled={!canCreateMoreClinicas} />
      <ExportButton disabled={isLoading || total === 0} />
    </TopToolbar>
  );
};

const ClinicaList = () => (
  <List actions={<ListActions />}>
    <Datagrid rowClick="show">
      <TextField source="direccion" />
      <ReferenceField source="franquiciaId" reference="Franquicia">
        <TextField source="nombre" />
      </ReferenceField>
      <TextField source="nombre" />
      <NumberField source="telefono" />
    </Datagrid>
  </List>
);

const ClinicaEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="direccion" required />
      <NumberInput
        type="number"
        source="cp"
        label="Codigo postal"
        required
        min={1000}
        max={53000}
      />
      <ReferenceInput source="franquiciaId" reference="Franquicia" required>
        <SelectInput optionText="nombre" required />
      </ReferenceInput>
      <TextInput source="nombre" required />
      <NumberInput min={600000000} max={999999999} source="telefono" />
    </SimpleForm>
  </Edit>
);
const ClinicaCreate = () => {
  const { permissions } = usePermissions();
  console.log("permissions: ", permissions);

  return (
    <Create redirect="list">
      <SimpleForm
        toolbar={
          <CreateAdminToolbar
            values={(context) => ({ role: "ADMIN", clinicaId: context.id })}
          />
        }
      >
        <TextInput source="direccion" required />
        <NumberInput
          type="number"
          source="cp"
          label="Codigo postal"
          required
          min={1000}
          max={53000}
        />
        {permissions === "SUPERADMIN" && (
          <ReferenceInput source="franquiciaId" reference="Franquicia" required>
            <SelectInput optionText="nombre" required />
          </ReferenceInput>
        )}
        <TextInput source="nombre" required />
        <NumberInput
          min={600000000}
          max={999999999}
          source="telefono"
          required
        />
      </SimpleForm>
    </Create>
  );
};

export const ClinicaShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Clinica">
        <VFormRow labeled>
          <TextField source="direccion" />
          <TextField source="cp" label="Código postal" />
        </VFormRow>
        <ReferenceField
          link="show"
          source="franquiciaId"
          reference="Franquicia"
        >
          <TextField source="nombre" />
        </ReferenceField>
        <TextField source="nombre" />
        <NumberField source="telefono" />
      </Tab>
      <Tab label="Usuarios">
        <ReferenceManyField
          perPage={10}
          pagination={<Pagination />}
          target="clinicaId"
          reference="User"
          label={false}
        >
          <Datagrid>
            <TextField source="email" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const clinica = (superadmin: boolean = false): ResourceProps => ({
  name: "Clinica",
  list: superadmin ? ClinicaList : undefined,
  create: superadmin ? ClinicaCreate : undefined,
  edit: superadmin ? ClinicaEdit : undefined,
  show: ClinicaShow,
  icon: StoreIcon,
});

export default clinica;
