import { DateField, DateInput, Labeled, useRecordContext } from "react-admin";

export const ReglaInput = (props: any) => {
  const record = useRecordContext();
  if (record && record.role !== "USER") return <></>;

  return <DateInput source="ultimaRegla" />;
};

export const ReglaField = () => {
  const record = useRecordContext();

  if (record && record.role !== "USER") return <></>;

  return (
    <Labeled>
      <DateField source="ultimaRegla" />
    </Labeled>
  );
};
