import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  FileInput,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  required,
  ResourceProps,
  RichTextField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  usePermissions,
} from "react-admin";
import PreviewImageField from "../forms/PreviewImageField";

import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import { VFormRow } from "../forms/VFormRow";
import { RichTextEditor } from "../forms/RichTextEditor";
import { LimitedLenghtTextField } from "../forms/LimitedLenghtTextField";

export const CalculadorEmbarazoList = (canDelete: boolean) => {
  return (
    <List title="Calculadora de embarazo">
      <Datagrid
        bulkActionButtons={canDelete ? undefined : false}
        rowClick="show"
      >
        <PreviewImageField
          width={100}
          height={100}
          source="imageUrl"
          label="Foto"
        />
        <NumberField source="semana" />
        <LimitedLenghtTextField source="mensaje" />
      </Datagrid>
    </List>
  );
};

//TODO: refactorizar el componente
export const CalculadoraEmbarazoEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm sx={{ maxWidth: 1200 }}>
        <NumberInput source="semana" />
        <ImageInput
          label="Imagen a mostrar"
          source="imageUrl"
          accept="image/*"
          isRequired
        >
          <PreviewImageField source="src" width={120} height={120} />
        </ImageInput>
        <RichTextEditor validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export const CalculadoraEmbarazoCreate = () => {
  const { permissions } = usePermissions();
  return (
    <Create redirect="show">
      <SimpleForm sx={{ maxWidth: 1200 }}>
        {permissions === "SUPERADMIN" && (
          <ReferenceInput source="franquiciaId" reference="Franquicia" required>
            <AutocompleteInput
              optionText="nombre"
              filterToQuery={(searchText) => ({ nombre: searchText })}
            />
          </ReferenceInput>
        )}
        <NumberInput source="semana" validate={required()} />
        <FileInput
          label="Imagen a mostrar"
          source="imageUrl"
          accept="image/*"
          isRequired
        >
          <PreviewImageField width={120} height={120} source="src" />
        </FileInput>
        <RichTextEditor validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export const CalculadorembarazoShow = () => (
  <Show>
    <SimpleShowLayout>
      <VFormRow labeled text="Calculadora de embarazo">
        <NumberField source="semana" />
        <PreviewImageField
          width={500}
          objectFit="contain"
          height={500}
          source="imageUrl"
          label="Foto"
        />
      </VFormRow>
      <RichTextField source="mensaje" />
    </SimpleShowLayout>
  </Show>
);

const calculadoraEmbarazo = (permission: string): ResourceProps => {
  console.log("permission: ", permission);
  return {
    name: "CalculadorEmbarazo",
    list:
      permission !== "SUPERADMIN"
        ? CalculadorEmbarazoList(permission === "FRANQUICIAADMIN")
        : undefined,
    create:
      permission === "SUPERADMIN" || permission === "FRANQUICIAADMIN"
        ? CalculadoraEmbarazoCreate
        : undefined,
    show: CalculadorembarazoShow,
    icon: ChildFriendlyIcon,
    edit:
      permission === "SUPERADMIN" || permission === "FRANQUICIAADMIN"
        ? CalculadoraEmbarazoEdit
        : undefined,
    options: { label: "Calculadora" },
  };
};

export default calculadoraEmbarazo;
