import { ImageField, ImageFieldProps, useRecordContext } from "react-admin";
import styled from "@emotion/styled";

export default function PreviewImageField({
  source,
  width = "unherit",
  height = "unherit",
  objectFit = "cover",
  ...rest
}: { record?: any; source?: any | undefined } & ImageStyledProps &
  ImageFieldProps) {
  let record = useRecordContext<any>();

  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }

  return record &&
    record.rawFile &&
    record?.rawFile?.type.startsWith("video") !== false ? (
    <video
      width={width}
      height={height}
      controls={false}
      preload="metadata"
      style={{ zIndex: 1, objectFit: "cover", pointerEvents: "none" }}
    >
      <source src={record.src} type="video/mp4" />
    </video>
  ) : (
    <StyledImage
      width={width}
      height={height}
      objectFit={objectFit}
      record={record}
      source={source || "src"}
      {...rest}
    />
  );
}

const dynamicStyle = ({ width, height, objectFit }: ImageStyledProps) => ({
  width,
  height,
  objectFit,
});

const StyledImage = styled(ImageField)<ImageStyledProps>`
  &&& img {
    ${dynamicStyle}
  }
`;

type ImageStyledProps = {
  width?: number | string;
  height?: number | string;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
};
