import { Edit, SimpleForm, TextInput, BooleanInput, email } from "react-admin";
import { ClinicaInput } from "./components/fields/ClinicaField";
import { VFormRow } from "../../forms/VFormRow";
import { FranquiciaInput } from "./components/fields/FranquiciaField";
import { ReglaInput } from "./components/fields/ReglaField";
import { BebeNombreInput } from "./components/fields/BebeNombreField";
import { FinServicioInput } from "./components/fields/FinServicioInput";

export const UserEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <VFormRow text="Identidad">
          <TextInput source="firstname" />
          <TextInput source="lastname" />
        </VFormRow>
        <TextInput
          source="email"
          validate={email("Introduce un email válido")}
        />
        <ClinicaInput />
        <VFormRow>
          <BooleanInput source="enabled" label="Activo" />
          <BooleanInput
            format={(v) => !v}
            parse={(v) => !v}
            source="passwordUpdated"
            label="Debe cambiar la contraseña"
          />
        </VFormRow>
        <BooleanInput source="mailSuscribed" label="Recibe emails" />
        <VFormRow>
          <ReglaInput />
          <BebeNombreInput />
        </VFormRow>
        <FranquiciaInput />
        <FinServicioInput />
      </SimpleForm>
    </Edit>
  );
};
