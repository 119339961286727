import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { useQueryClient } from "react-query";

export default function useGetWeeks(userId: string) {
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const [weeks, setWeeks] = useState([0]);

  useEffect(() => {
    updateWeeks();
    async function updateWeeks() {
      const a = await dataProvider.getWeeksAvailables(userId);
      setWeeks(a.data);
    }
  }, [queryClient.isFetching()]);

  return weeks;
}
